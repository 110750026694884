$color_1: #808080;
$color_2: white;
$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: rgb(255, 255, 255);
$background-color_3: transparent;
$background-color_4: #007bff;
$background-color_5: #0056b3;
$background-color_6: rgba(128, 128, 128, 0.152);
$background-color_7: #bdbdbd;
.shareBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color_7;
  padding: 0.5rem;
  color: $color_2;
  border-radius: 20px;
  border: none;
  font-size: 15px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color_1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modal {
  background-color: $background-color_2;
  padding: 20px;
  border-radius: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
}
.modal-title {
  font-size: 24px;
  color: $color_1;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: $background-color_3;
  font-size: 30px;
  cursor: pointer;
  color: $color_1;
}
.modal-content {
  padding: 20px;
}
.share-buttons-container {
  width: 100%; /* Occupy full width */
  overflow: hidden; /* Hide overflow content */
}
.scroll-container {
  display: flex;
  align-items: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px 0; /* Adjust padding as needed */
}
.btn-background {
  width: 50px;
  height: 50px;
  background-color: $background-color_2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}
.scroll-buttons {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  margin: 0 10px;
  color: $color_1;
}
.content-wrapper {
  overflow: hidden;
  width: 600px;
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
/* Hide scrollbar specifically for WebKit browsers */
.content-wrapper::-webkit-scrollbar {
  display: none;
}
.content {
  display: flex;
  transition: transform 0.3s ease;
}
.item {
  width: 80px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  cursor: pointer;
  background-color: $background-color_6;
  margin-bottom: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_1;
}
.icon2 {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  font-size: 14px;
  text-align: center;
  color: $color_1;
}
.modal-footer {
  padding: 20px;
  border-top: 1px solid #ccc;
}
.link-container {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  overflow: auto;
}
.page-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  color: $color_1;
}
.copy-button {
  background-color: $background-color_4;
  color: $color_2;
  border: none;
  border-radius: 20px;
  padding: 10px 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;
  &:hover {
    background-color: $background-color_5;
  }
}
@media screen and (max-width: 600px) {
  .link-container {
    margin-top: 10px;
  }

  .page-link {
    padding: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .copy-button {
    font-size: 15px;
    padding: 8px 10px;
  }

  .scroll-buttons {
    font-size: 20px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color_1;
  z-index: 999;
  display: none;
}
.overlay.active {
  display: block;
}
/* SUBMODAL */
.embed-code-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $background-color_2;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.embed-code-content {
  text-align: center;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}
.title2 {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: $background-color_1;
}
.copy-button2 {
  border: none;
  background-color: $background-color_2;
  font-size: 16px;
  padding: 10px 18px;
  cursor: pointer;
  &:hover {
    background-color: $background-color_6;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }
}
.preview-iframe {
  pointer-events: none;
}
