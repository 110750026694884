@use '../../variables';
.adminPanelMainContainer {
  @extend %fullHeightWithHeader;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.adminSideNavOpen {
  max-width: 180px !important;

  background-color: variables.$primaryColor;
  padding-top: 0.5rem;

  a {
    text-decoration: none;
  }
}
.adminSideNavClose {
  max-width: 56px !important;
  background-color: variables.$primaryColor;
  padding-top: 0.5rem;

  a {
    text-decoration: none;
  }
}
.adminSideNavHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  button {
    background-color: variables.$primaryColor;
  }
}

.adminOutletContainer {
  @extend %fullHeightWithHeader;
  overflow: auto;
}

.organisationTabsMainContainer {
  @extend .adminOutletContainer;
  overflow: auto;
}

.createOrganisationMainContainer {
  padding: 2rem 1rem 0 1rem;
  width: 100%;
}

.dataGridContainer {
  padding: 1rem 1rem 0 1rem;
  height: calc(var(--vh, 1vh) * 100 - variables.$headerHeight - 8.2rem);
  width: 100%;
}

.categoryDataGridContainer {
  padding: 1rem 1rem 0 1rem;
  height: calc(var(--vh, 1vh) * 100 - variables.$headerHeight - 15rem);
  width: 100%;
}

.categoryCellInvalid {
  background-color: lightcoral;
}
.print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$fancyColor;
  padding: 1.5rem;
  img {
    width: 220px;
  }
  svg {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.qrcodes-buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem;
  button {
    text-transform: none;
  }
}
