.dropAreaContainer {
  width: 100%;
  height: 150px;
  border: 2px dashed rgb(0, 139, 253);
  border-radius: 5px;
  background-color: rgb(210, 234, 251);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
  &:hover {
    opacity: 0.5;
  }
}
.activeDrag {
  opacity: 0.5;
}
.fileDnDMainContainer {
  -webkit-box-shadow: 0px 0px 20px -9px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 20px -9px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 20px -9px rgba(0, 0, 0, 1);
}
