@use '../../variables';
.appShellContainer {
  @extend %fullHeight;
  overflow: hidden;
}
.appBarStyle {
  height: variables.$headerHeight;
  max-height: variables.$headerHeight;
  z-index: 999;
}

.print-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: variables.$fancyColor;
  padding: 1.5rem;
  img {
    width: 220px;
  }
  svg {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.cookie-banner {
  background: #000a27;
  border-radius: 10px;
  text-align: center;
  color: white;
  border: none;
  opacity: 0.85;
  padding: 20px;
}

.cookie-banner-iframe {
  font-size: 0.8em;
  padding: 5px;
}

.cookie-banner-iframe h3 {
  margin: 4px;
}

.cookie-banner .customize-button,
.cookie-banner button,
.modal-buttons .customize-button {
  background: transparent;
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  margin: 5px;
  padding: 8px 20px;
  margin-top: 20px;
}

.cookie-banner-iframe .customize-button,
.cookie-banner-iframe button,
.modal-buttons .customize-button {
  margin-top: 5px;
}

.cookie-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.cookie-modal.active {
  display: flex;
  /* Display when active */
}

@media screen and (max-width: 600px) {
  .cookie-modal-content {
    max-width: 90%;
    max-height: 90%;
  }
}

@media screen and (min-width: 600px) {
  .cookie-modal-content {
    max-width: 50%;
    max-height: 50%;
  }
}

.cookie-modal-content {
  background-color: #000a27;
  opacity: 0.85;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  color: white;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 25px;
  color: white;
  margin-right: 10px;
}

.expand {
  background-color: transparent;
  text-decoration: underline;
  font-style: italic;
  border: none;
  color: rgba(64, 122, 222, 0.914);
  cursor: pointer;
}

.text {
  font-size: 14px;
}

.extraText {
  font-size: 12px;
  padding: 20px;
  margin: 20px;
  background-color: #ffffff2c;
  border-radius: 10px;
}
