@use '../../variables';
.google-map-container-style {
  @extend %fullHeightWithHeader;
  width: 100%;
}

.categoryButton {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}
.categoryButton.selected {
  border: 2px solid black;
  margin: -2px;
}

.categorySelector {
  max-height: 50vh;
  vertical-align: center;
  display: flex;
  justify-content: flex-end;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: none;
    border-radius: 1em;
    margin-left: 1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;

    border-radius: 1em;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.categorySelectorModal {
  background: rgba(255, 255, 255, 0.95);
  width: 15rem;
  padding: 10px;
  overflow-y: auto;
  border-radius: 2em;
}
.filterControlsBox {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  margin: 1rem;
  vertical-align: center;
  padding-left: 5px;
}

.searchField {
  border: none;
  background: transparent;
  outline: none;
  margin: 0.5rem;
  height: 1.5em;
  width: 100%;
}

.mobileViewSearchFilterContainer {
  display: 'flex';
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  animation: fade-in 2s;
  background-color: red;
}

.fadeInComponent {
  animation: fade-in 2s;
}
@keyframes fade-in {
  from {
    opacity: 0;
    scale: 2;
  }
  to {
    opacity: 100;
    scale: 1;
  }
}
