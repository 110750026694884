@use '../../variables';
.transform-screen-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  //align-items: center;
  height: calc(100vh - (variables.$headerHeight + variables.$footerHeight));
  max-height: calc((var(--vh, 1vh) * 100) - (variables.$headerHeight + variables.$footerHeight));
  overflow: scroll;
  width: 100%;
  background-color: variables.$primaryColor;
}
.footer {
  height: variables.$footerHeight;
  button {
    height: variables.$footerHeight;
  }
}
.transform-screen-item {
  background-color: variables.$primaryColor;
  width: 100%;
}

.transform-select-option {
  color: #f5f5f5;

  .transform-select-checkbox {
    color: #f5f5f5;
  }
}
