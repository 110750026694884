@use '../../variables';
.scanner-screen-main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc((var(--vh, 1vh) * 100) - (variables.$headerHeight));
  max-height: calc((var(--vh, 1vh) * 100) - (variables.$headerHeight));
  width: 100%;
  background-color: variables.$primaryColor;
  overflow-y: scroll;
}

.scanner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.footer {
  height: variables.$footerHeight;
}

.blink_me {
  animation: blinker 0.5s linear infinite alternate;
}

@keyframes blinker {
  50% {
    opacity: 0;
    background-color: rgba(225, 255, 0, 0.775);
  }
}
