$headerHeight: 3.5rem;
$footerHeight: 2.5rem;
$primaryColor: #000927;
$fancyColor: #15bcc4;
%fullHeight {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-y: scroll;
}
%fullHeightWithHeader {
  height: calc(var(--vh, 1vh) * 100 - $headerHeight);
  max-height: calc(var(--vh, 1vh) * 100 - $headerHeight);
  width: 100%;
  overflow-y: scroll;
}
%fullHeightWithHeaderFooter {
  height: calc(var(--vh, 1vh) * 100 - ($headerHeight + $footerHeight));
  max-height: calc(var(--vh, 1vh) * 100 - ($headerHeight + $footerHeight));
  width: 100%;
  overflow-y: scroll;
}
